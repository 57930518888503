// Copyright 2017-2023 @polkadot/types authors & contributors
// SPDX-License-Identifier: Apache-2.0

export const runtime = {
  Metadata: [{
    methods: {
      metadata: {
        description: 'Returns the metadata of a runtime',
        params: [],
        type: 'OpaqueMetadata'
      }
    },
    version: 1
  }]
};