// Copyright 2017-2023 @polkadot/types-known authors & contributors
// SPDX-License-Identifier: Apache-2.0

/* eslint-disable sort-keys */

const versioned = [{
  minmax: [0, undefined],
  types: {
    // nothing, limited runtime
  }
}];
export default versioned;